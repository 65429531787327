
  import { Component, Vue } from 'vue-property-decorator'

  @Component({ components: {} })
  export default class MemberFilter extends Vue {
    options = [
      {
        value: 'Option1',
        label: 'Option1'
      },
      {
        value: 'Option2',
        label: 'Option2'
      },
      {
        value: 'Option3',
        label: 'Option3'
      },
      {
        value: 'Option4',
        label: 'Option4'
      },
      {
        value: 'Option5',
        label: 'Option5'
      }
    ]
    value = ''
  }
