
  import { Component, Vue } from 'vue-property-decorator'

  @Component({ components: {} })
  export default class CalendarView extends Vue {
    calendars = [
      {
        id: '1',
        name: 'My Calendar',
        color: '#ffffff',
        bgColor: '#9e5fff',
        dragBgColor: '#9e5fff',
        borderColor: '#9e5fff'
      },
      {
        id: '2',
        name: 'Company',
        color: '#00a9ff',
        bgColor: '#00a9ff',
        dragBgColor: '#00a9ff',
        borderColor: '#00a9ff'
      }
    ]

    scheduleList = [
      {
        id: '1',
        calendarId: '1',
        title: 'my schedule',
        category: 'time',
        dueDateClass: '',
        start: new Date().toString(),
        end: new Date(new Date().getTime() + 30 * 60000).toString()
      },
      {
        id: '2',
        calendarId: '2',
        title: 'second schedule',
        category: 'time',
        dueDateClass: '',
        start: new Date().toString(),
        end: new Date(new Date().getTime() + 30 * 60000).toString()
      }
    ]

    template = {
      weekDayname: function(model: any) {
        return '<span class="tui-full-calendar-dayname-name">' + model.dayName + '</span>'
      }
    }
  }
