
  import { Component, Vue } from 'vue-property-decorator'
  import { dayOfWeek } from '@/config'

  interface ScheduleInterface {
    time: string
    mon: Record<string, any>
    tue: Record<string, any>
    wed: Record<string, any>
    thu: Record<string, any>
    fri: Record<string, any>
    sat: Record<string, any>
    sun: Record<string, any>
  }

  @Component({ components: {} })
  export default class ScheduleTable extends Vue {
    labelDayOfWeek = dayOfWeek

    data: ScheduleInterface[] = []

    init() {
      for (let i = 0; i < 10; i++) {
        this.data.push({
          time: '2h30',
          mon: {
            name: 'thang'
          },
          tue: {
            name: 'thang 1'
          },
          wed: {
            name: 'thang 2'
          },
          thu: {
            name: 'thang 3'
          },
          fri: {
            name: 'thang 4'
          },
          sat: {
            name: 'thang 5'
          },
          sun: {
            name: 'thang 6'
          }
        })
      }
    }

    created() {
      this.init()
    }
  }
