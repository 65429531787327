
  import { Component, Vue } from 'vue-property-decorator'
  import ScheduleTable from '../components/schedules/ScheduleTable.vue'
  import CalendarView from '../components/schedules/CalendarView.vue'
  import MemberFilter from '../components/member/MemberFilter.vue'

  @Component({ components: { ScheduleTable, CalendarView, MemberFilter } })
  export default class SchedulePage extends Vue {
    view: 'calendar' | 'setting' = 'calendar'

    handleOpenSetting() {
      this.view = this.view === 'setting' ? 'calendar' : 'setting'
    }
  }
